import {
    createRouter,
    createWebHashHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteRecordRaw
} from 'vue-router'
import {Request} from '@/http/request'
import store from '@/store'
import {getToken, setToken} from '@/plugins/cookie'
import {flatten} from '@/public/js/tool'

const routes: Array<RouteRecordRaw> = [
    {//公共区域
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        redirect: '/settings/smsSettingsNew',
        meta: {
            title: '',
            icon: 'icon-copy-4-07',
        },
        children: [
            {
                path: '/settings/smsSettingsNew',
                name: '状态查询(新)',
                component: () => import('@/views/settings/smsSettingsNew.vue'),
                meta: {
                    title: '状态查询(新)',
                    icon: 'icon-cishu_data-sheet-03',
                },
            },
            {
                path: '/settings/noteSettingsNew',
                name: '短信验证(新)',
                component: () => import('@/views/settings/noteSettingsNew.vue'),
                meta: {
                    title: '短信验证(新)',
                    icon: 'icon-huadanchaxun',
                },
                
            },
            
            {
                path: '/settings/textaPendulum',
                name: '能力号激活',
                component: () => import('@/views/settings/textaPendulum.vue'),
                meta: {
                    title: '能力号激活',
                    icon: 'icon-cishu_data-sheet-03',
                },
            }, 

            {
                path: '/settings/ctccIp',
                name: '能力号IP',
                component: () => import('@/views/settings/ctccIp.vue'),
                meta: {
                    title: '能力号IP',
                    icon: 'icon-cishu_data-sheet-03',
                },
            }, 
        ]
    },
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: () => import('@/views/Login.vue')
    // },
    // {
    //     path: '/asr',
    //     name: 'asr',
    //     component: () => import('@/views/Asr.vue')
    // },
    // {
    //     path: '/cooperation',
    //     name: 'Cooperation',
    //     component: () => import('@/views/Cooperation.vue')
    // },
    // {
    //     path: '/manager/login',
    //     name: 'admin-login',
    //     component: () => import('@/views/AdminLogin.vue')
    // },
    // {
    //     // 微信公众号vos质检详情页面
    //     path: '/wechat/record',
    //     name: 'wechatRecord',
    //     component: () => import('@/views/open/wechat/record/index.vue')
    // }
    //   {
    //     path: '/:pathMatch(.*)*',
    //     name: 'not-found',
    //     component: () => import('@/views/error/404')
    //   }{
    //     path: '/checkDetail',
    //     name: 'check-detail',
    //     component: () => import('@/views/open/checkDetail')
    //   }
]

const router = createRouter({
    history: createWebHashHistory(),
    // history: createWebHistory(process.env.BASE_URL),
    routes
})

const resetRouter = () => {
    const newRouter: any = createRouter({
        history: createWebHashHistory(),
        routes
    });
    (router as any).matcher = newRouter.matcher
}

const whiteList = ['/settings/noteSettingsNew','/settings/smsSettingsNew','/','/settings/textaPendulum'] // no redirect whitelist


//获取url参数
function getQueryVariable(variable:any) {
    var query = location.href.split('?')[1];
    console.log('getQueryVariable', query)
    if (query) {
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
    }
    return (false);
}


router.beforeEach((to, from, next) => {
    // let jwt = getQueryVariable('token');
    // console.log('beforeEach jwt', jwt)
    // if (to.fullPath == "/dashboard?changeToken=true") {
    //     location.reload();
    //   }
    // if (jwt) {
    //     Request.axiosInstance.post('/web/auth/free-login', {
    //         jwt
    //     }).then((val: Record<string, any>) => {
    //         console.log(val)
    //         if (val.code === 200 && val.data) {
    //             setToken(val.data)
    //             tokenHandle(to, from, next)
    //         }

    //     })

    // } else {
         next()
    // }

})

const tokenHandle = (to, from, next) => {
    // if (getToken()) {
    //     // 本地有token(虽然有，但可能已经过期)
    //     if (to.path == '/login' || to.path == '/manager/login') { // 有token不需要访问login, 直接去根路由，如果token失效会被axios拦截并进入/login
    //         next({path: '/'})
    //     }
    //     if (store.getters.userType.length == 0) {  // 如果没有用户类型 一般都是页面刷新导致 store 清空
    //         Request.axiosInstance.post('/system/admin-user/user-type').then((res) => {
    //             // 重新设置用户类型
    //             const userType = res.data
    //             store.dispatch('setUserType', userType)
    //             // 管理员信息重设
    //             if (userType == 'system') {
    //                 Request.axiosInstance.post('/system/admin-user/info').then((res) => {
    //                     store.dispatch('setUser', res.data).then(() => {
    //                         Request.axiosInstance.post('/system/admin-user/user-menu').then((res) => {
    //                             store.dispatch('setMenu', res.data)
    //                             buildRouter(res.data, next, to)
    //                         })
    //                     })
    //                 })
    //             }

    //             // 注册用户重设
    //             if (userType == 'web') {
    //                 Request.axiosInstance.post('/web/user/info').then((res) => {
    //                     store.dispatch('setUser', res.data).then(() => {
    //                         Request.axiosInstance.post('/web/user/user-menu').then((res) => {
    //                             store.dispatch('setMenu', res.data)
    //                             buildRouter(res.data, next, to)
    //                         })
    //                     })
    //                 })
    //             }

    //         })

    //     } else {
    //         next()
    //     }
    // } else {
        // 进入此处，说明本地没有token

        
        if (whiteList.indexOf(to.path) !== -1) { // 白名单直接进入
            next('/')
        } else {	// 非白名单去登录页
            next('/')
        }
    // }
}

// 重构路由
const buildRouter = (routers: any, next: NavigationGuardNext, to: RouteLocationNormalized) => {

    //测试新添加的页面
    // const testListRoute = addTestRoute();
    // if (testListRoute && testListRoute.length > 0) {
    //     for (let i = 0; i < testListRoute.length; i++) {
    //         const route = routers[0].children.find(item => item.appId == testListRoute[i].appId);
    //         if (route && route.children) {
    //             route.children.splice(0, 0, ...testListRoute[i].children);
    //         }
    //     }
    //     // console.log('testListRoute', testListRoute, routers)
    // }

    const asyncRouter = filterAsyncRouter(routers)
    // asyncRouter.push({ path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/404', hidden: true })
    resetRouter();

    for (const route of asyncRouter) {
        router.addRoute('home', route)
    }
    // (router as any).options.routes[0].children.push(...asyncRouter) // 将所有路由添加到根路由的children中
    // router.addRoute(router.options.routes[0]) // 动态添加可访问路由表
    // console.log(router.getRoutes())
    next({...to, replace: true})
}

// const addTestRoute = () => {
//
//     const list: any[] = [];
//     const workSheet = {
//         appId: 2,
//         children: [],
//         createTime: "2021-09-23 11:32:27",
//         icon: "icon-zhijianzonglan",
//         id: "998",
//         name: "质检工单",
//         parentId: "29",
//         path: ",-1,29,",
//         remark: "",
//         route: "/open/workOrder/index",
//         sort: 0,
//         status: 0,
//         type: 0,
//         updateTime: "2022-04-15 10:11:56",
//         url: "/open/workOrder/index",
//     }
//     list.push({
//         appId: 2,
//         children: [workSheet],
//     });
//     return list;
// }
const filterAsyncRouter = (routers: any) => {
    return flatten(routers)
        .filter(_router =>
            _router.route && _router.route.length && _router.url && _router.url.length
        ).map(_router => ({
                path: _router.url,
                name: _router.url.replaceAll("/", ""),
                meta: {
                    title: _router.name,
                    icon: _router.icon,
                    id: _router.id,
                    path: _router.path
                },
                component: () => process.env.NODE_ENV === "development" ? require(`@/views${_router.route}`) : import(`@/views${_router.route}`)
                // component: (resolve: any) => require([`@/views${_router.route}`], resolve)
            })
        )
}

export default router
